import styled from "styled-components";

/** Global Module Dependencies */
import Text from "common/elements/Text";

export const sizes = {
	MD: "md",
	LG: "lg",
	XL: "xl",
	XXL: "xxl",
};

const style = {
	[sizes.MD]: {
		fontSize: "20px",
	},
	[sizes.LG]: {
		fontSize: "24px",
	},
	[sizes.XL]: {
		fontSize: "40px",
	},
	[sizes.XXL]: {
		fontSize: "64px",
	},
};

const Heading = styled(Text).attrs({ bold: true })`
	${({ size }) => style[size || sizes.MD]}
	font-weight: 600;
	line-height: 1.1;
`;

export default Heading;
