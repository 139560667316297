import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
	baseQuery: fetchBaseQuery({
		// Fill in your own server starting URL here
		baseUrl: "https://karega.com",
	}),
	endpoints: (build) => ({
		// A query endpoint with no arguments
		page: build.mutation({
			query: () => "page",
		}),
	}),
});

export const { usePageMutation } = api;