import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

// Import the API object
import { api } from "app-state/api";

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
	// Automatically calls `combineReducers`
	reducer: {
		// Add the generated RTK Query "API slice" caching reducer
		[api.reducerPath]: api.reducer,
		// Add any other reducers
	},
	// Add the RTK Query API middleware
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export const AppDispatch = typeof store.dispatch;
export const RootState = typeof store.getState;

setupListeners(store.dispatch);

export default store;