/* index.js */

/** External Module Dependencies */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

/** Global Module Dependencies */
import store from "app/store";
import { ModalProvider } from "common/elements/Modal";
import { RefreshContextProvider } from "contexts/RefreshContext";
import { ThemeContextProvider } from "contexts/ThemeContext";

/** Internal Module Dependencies */
import App from "./App";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<Provider store={store}>
		<RefreshContextProvider>
			<ThemeContextProvider>
				<BrowserRouter>
					<ModalProvider>
						<App />
					</ModalProvider>
				</BrowserRouter>
			</ThemeContextProvider>
		</RefreshContextProvider>
	</Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
